import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-xxl-5 mb-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTable = _resolveComponent("UserTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserTable, {
      "widget-classes": "mb-5 mb-xl-8",
      tableData: _ctx.myList.data
    }, null, 8, ["tableData"])
  ]))
}