<template>
  <!--begin::Tables Widget 10-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcı Listesi</span>

        <span class="text-muted mt-1 fw-bold fs-7" v-if="tableData">Toplam {{ tableData.length }} sistem kullanıcısı.</span>
      </h3>
      <div class="card-toolbar">

          <router-link to="/apps/sys/user/addnew" class="btn btn-sm btn-light-primary">
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/arrows/arr075.svg"/>

          </span>
          Yeni Kullanıcı
          </router-link>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Header2-->
    <div class="card-header border-0 pt-5">
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg"/>
          </span>
          <input
              type="text"
              class="form-control form-control-solid w-250px ps-15"
              placeholder="Ara"
              v-model="searchName"
          />

          <el-tooltip placement="top">
            <template #content> Aşağıdaki alanlarda arama yapabilirsiniz<br /><br/>
            <ul>
              <li v-for="(item, key) in filterFieldName" :key="key">{{ item }}</li>
            </ul>
            </template>
            <i
                class="fas fa-exclamation-circle ms-3 fs-15"
                data-bs-toggle="tooltip"
            ></i>
          </el-tooltip>
        </div>
        <!--end::Search-->
      </div>
    </div>
    <!--end::Header2-->

    <!--begin::Body-->
    <div class="card-body pt-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
            class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
        >
          <!--begin::Table head-->
          <thead>
          <tr class="fw-bolder text-muted bg-light">
            <th class="ps-4 min-w-325px rounded-start">İsim/Kullanıcı
              <a
                  @click="sortData('Name', 'tableData')"
                  class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
              >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                          src="media/icons/duotune/arrows/arr032.svg"
                      />
                    </span>
              </a>

            </th>
            <th class="min-w-200px">İletişim
              <a
                  @click="sortData('Email', 'tableData')"
                  class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
              >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                          src="media/icons/duotune/arrows/arr032.svg"
                      />
                    </span>
              </a>
            </th>
            <th class="min-w-200px">Başlangıç Tarihi
              <a
                  @click="sortData('StartDate', 'tableData')"
                  class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
              >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                          src="media/icons/duotune/arrows/arr032.svg"
                      />
                    </span>
              </a>
            </th>
            <th class="min-w-150px">Çalışma</th>
            <th class="min-w-80px">Durum</th>
            <th class="w-150px">Aksiyon</th>
          </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
          <template v-for="(systemuser, index) in filteredUserList" :key="index">
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-45px me-5">
                    <img
                        v-if="systemuser.Avatar"
                        alt="Pic"
                        :src="MasterConfig.general.imagePath+systemuser.Avatar"
                    />
                    <span
                        v-else-if="systemuser.Name !== undefined"
                        :class="`bg-light-danger text-danger`"
                        class="symbol-label fw-bolder"
                    >
                        {{ systemuser.Name.charAt(0) }}
                      </span>
                  </div>
                  <!--end::Avatar-->

                  <!--begin::Name-->
                  <div class="d-flex justify-content-start flex-column">
                    <span
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-6 cursor-pointer"
                    >{{ systemuser.Name + " " + systemuser.Lastname }}</span
                    >

                    <span
                        class="
                          text-muted text-hover-primary
                          fw-bold
                          text-muted
                          d-block
                          fs-7
                          cursor-pointer
                        "
                    >
                      <span class="text-dark">Kullanıcı adı: </span>
                      {{ systemuser.Username }}
                    </span>
                  </div>
                  <!--end::Name-->
                </div>
              </td>
              <td class="">
                <span
                    class="
                      text-dark
                      fw-bolder
                      text-hover-primary
                      d-block
                      mb-1
                      fs-6
                      cursor-pointer
                    "
                >{{ systemuser.Email }}</span
                >
                <span class="text-muted fw-bold text-muted d-block fs-7">{{ systemuser.Mobile }}</span
                >
              </td>
              <td class="text-muted fw-bold">
                <div>
                  {{
                    convertToDate(systemuser.StartDate)
                  }}
                  <span v-if="systemuser.FinishDate == null "
                        class="badge badge-light-success"
                  >Devam Ediyor</span
                  >
                </div>
                <div v-if="systemuser.FinishDate != null">
                  {{ convertToDate(systemuser.FinishDate) }}
                  <span
                        class="badge badge-light-danger"
                  >Ayrıldı</span
                  >
                </div>
              </td>
              <td>
                <div>
                <span v-if="systemuser.ShiftType == 'FULL-TIME' "
                      class="badge badge-light-primary"
                >{{ systemuser.ShiftType }}</span
                >
                  <span v-else-if="systemuser.ShiftType == 'PART-TIME' "
                        class="badge badge-light-warning"
                  >{{ systemuser.ShiftType }}</span
                  >
                </div>
                <div>
                  <img
                      class="w-15px h-15px rounded-1 ms-2"
                      :src="'media/flags2/'+systemuser.Country+'.svg'"
                      alt="Country"
                  />
                  {{ systemuser.City }}
                </div>
              </td>
              <td class="">
                <div>
                  <span v-if="systemuser.is_active == 'active' "
                        class="badge-light-success badge"
                  >{{ systemuser.is_active }}</span>

                  <span v-else-if="systemuser.is_active == 'passive' "
                        class="badge-light-danger badge"
                  >{{ systemuser.is_active }}</span>
                </div>
                <div class="mt-1">
                  <span class="badge-light-info  badge">{{ systemuser.Type }}</span>
                </div>
              </td>
              <td class="align-content-start w-50px">
                  <router-link :to="'/apps/sys/user/update/'+systemuser.ID">
                <span
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="media/icons/duotune/art/art005.svg"/>
                    </span>
                </span>
                  </router-link>
              </td>
            </tr>
          </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 10-->
</template>

<script lang="js">

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import { dateConvert } from "@/core/filters/datatime"


export default defineComponent({
  name: "user-list",
  components: {
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Name', 'Lastname', 'Username', 'Email', 'Mobile'],
      filterFieldName: ['İsim', 'Soyisim', 'Kullanıcı Adı', 'Email', 'Telefon'],
    }
  },
  props: {
    widgetClasses: String,
    tableData: Object,
  },
  computed: {
    filteredUserList: function () {
      if (this.tableData !== undefined){
        return this.tableData.filter(systemuser =>
            (systemuser.Name !== undefined && !(systemuser.Name).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (systemuser.Lastname !== undefined && !(systemuser.Lastname).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (systemuser.Username !== undefined && !(systemuser.Username).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (systemuser.Email !== undefined && !(systemuser.Email).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (systemuser.Mobile !== undefined && !(systemuser.Mobile).toLowerCase().indexOf(this.searchName.toLowerCase()))
        );
      }else{
        return [];
      }
    },

  },
  methods: {
    ...dateConvert.method,

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },
  setup() {
    const myParams = computed(() => {
      return store.getters.generalParams;
    });
    //console.log(MasterConfig);
    return {
      myParams,
      MasterConfig,
    }
  },
});
</script>
